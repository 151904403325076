<template>
  <div>
    <div class="list-item">
      <div class="item-panel">
        <div class="item-content">
          <p class="criteria-name">
              {{ $t('txt.order_id') }}:
          </p>
          <p class="criteria-value"> {{ item.id }} </p>
      </div>
        <div class="item-content">
          <p class="criteria-name">
              {{ $t('txt.shipping_address') }}:
          </p>
          <p class="criteria-value" v-if="item.shipping_address && item.shipping_address.address">
              {{ item.shipping_address.address }}
          </p>
      </div>
        <div class="item-content" v-for="(input, key) in status" :key="`A-${key}`">
          <p class="criteria-name">
              {{ input.labelName }}:
          </p>
          <p class="criteria-value">
              {{ input.inputValue }}
          </p>
        </div>
        <div class="item-content" v-if="item.shipping_address && item.shipping_address.phone">
          <p class="criteria-name">
              {{ $t('txt.phone') }}:
          </p>
          <p class="criteria-value" >
            <a :href="`tel:${item.shipping_address.phone}`" class="criteria-link">
              {{ item.shipping_address.phone }}
            </a>
          </p>
        </div>
    </div>
      <b-button class="item-detail-button" v-b-modal.modal="`modal-${this.item.id}`"> {{ $t('txt.order_details') }} </b-button>
    </div>
    <div>
      <b-modal :id="`modal-${this.item.id}`" class="item-modal" :title="$t('txt.order_details')" hide-footer>
          <div class="item-modal">
              <div class="item-modal-detail" v-for="(input, key) in status" :key="`B-${key}`">
                <span> {{ input.labelName }}: </span>
                <p> {{ input.inputValue }} </p>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in customer" :key="`C-${key}`">
                <span> {{ input.labelName }}: </span>
                <p v-if="!input.phone"> {{ input.inputValue }} </p>
                <p v-else> <a :href="`tel: ${input.inputValue}`" class="item-modal-detail-link"> {{ input.inputValue }} </a> </p>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in time" :key="`D-${key}`">
                <span> {{ input.labelName }}: </span>
                <p> {{ input.inputValue }} </p>
              </div>
              <div class="item-modal-detail item-modal-detail--product" v-for="(input, key) in products" :key="`E-${key}`">
                <span> {{input.labelName}}: {{input.inputValue.length}} </span>
                <a v-b-toggle.product-list class="item-modal-detail-link"> {{ $t('txt.see_all') }} </a>
                <b-collapse id="product-list" class="product-list">
                  <p class="product" v-for="(product, key) in input.inputValue" :key="`prod-${key}`">
                    <span class="product__title" v-if="product.name"> {{product.name.ro}} </span>
                    <span class="product__multiply"> x </span>
                    <span class="product__qty" v-if="product.pivot"> {{product.pivot.qty}} </span>
                  </p>
                </b-collapse>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in payment_method" :key="`F-${key}`">
                <span> {{ input.labelName }}: </span>
                <p> {{ input.inputValue }} </p>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in shipping_address" :key="`G-${key}`">
                <span> {{ input.labelName }}: </span>
                <p> {{ input.inputValue }} </p>
              </div>
              <div class="item-modal-detail item-mentions" v-for="(input, key) in observations" :key="`H-${key}`">
                  <span> {{ input.labelName }} </span>
                  <p> {{ input.inputValue }} </p>
              </div>
              <div class="item-modal-detail item-modal-total" v-for="(input, key) in total" :key="`I-${key}`">
                <p class="item-total-value">
                    <span class="total-value-title"> {{ input.labelName }} </span>
                     {{ input.inputValue }} LEI
                 </p>
              </div>
          </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryItem',
  data () {
    return {
      status: [
        {
          inputName: 'status',
          labelName: 'Status',
          inputValue: (this.item && this.item.status) ? this.item.status.name.ro : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      customer: [
        {
          inputName: 'full_name',
          labelName: this.$t('txt.full_name'),
          inputValue: (this.item && this.item.customer) ? this.item.customer.first_name + ' ' + this.item.customer.last_name : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'phone',
          labelName: this.$t('txt.phone'),
          inputValue: (this.item && this.item.shipping_address) ? this.item.shipping_address.phone : null,
          icon: 'fas fa-sort-numeric-up',
          phone: true
        }
      ],
      time: [
        {
          inputName: 'created_at',
          labelName: this.$t('txt.created_at'),
          inputValue: (this.item && this.item.customer) ? this.item.created_at : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'delivered_at',
          labelName: this.$t('txt.delivered_at'),
          inputValue: (this.item && this.item.customer) ? this.item.delivered_at : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'time_elapsed',
          labelName: this.$t('txt.time_elapsed'),
          inputValue: (this.item && this.item.customer) ? (this.item.time_elapsed / 60).toFixed(2) : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      products: [
        {
          inputName: 'products',
          labelName: this.$t('txt.products'),
          inputValue: (this.item && this.item.products) ? this.item.products : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      payment_method: [
        {
          inputName: 'paymentMethod',
          labelName: this.$t('txt.payment_method'),
          inputValue: (this.item && this.item.payment_type) ? this.item.payment_type.name.ro : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      shipping_address: [
        {
          inputName: 'address',
          labelName: this.$t('txt.address'),
          inputValue: (this.item && this.item.shipping_address) ? this.item.shipping_address.address : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'city',
          labelName: this.$t('txt.city'),
          inputValue: (this.item && this.item.shipping_address) ? (this.item.shipping_address.city ? this.item.shipping_address.city.name : null) : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      observations: [
        {
          inputName: 'observations',
          labelName: this.$t('txt.observations'),
          inputValue: (this.item && this.item.observations) ? this.item.observations : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      total: [
        {
          inputName: 'total',
          labelName: this.$t('txt.total'),
          inputValue: (this.item && this.item.total) ? this.item.total : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ]
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  created () {
  }
}
</script>

<style lang="css" scoped>
    .item-modal-detail-link:hover {
        cursor:pointer;
    }
</style>
