<template>
  <div class="list">
    <div class="page-title">
      <h1> Istoric </h1>
    </div>
    <div class="history-container" id="history-container">
      <HistoryItem v-for="item in items" :item="item" :key="item.id"></HistoryItem>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="pagination-wrapper">
        <div class="results-message">
          <p>
            <i class="fas fa-info-circle"></i>
            {{$t('pagination.info', { from: this.meta.from, to: this.meta.to, total: this.meta.total })}}
          </p>
        </div>
        <ul class="pagination-box">
          <li class="pagination-point" v-if="meta.current_page !== 1">
            <a href="javascript:void(0)" @click="applyQuery({ page: 1 })" class="Previous"><i
                    class="fas fa-angle-double-left pag-left"></i></a>
          </li>
          <li class="pagination-point" v-if="meta.current_page !== 1">
            <a href="javascript:void(0)" @click="applyQuery({ page: meta.current_page-1 })"
               class="Previous"><i class="fas fa-angle-left pag-left"></i></a>
          </li>
          <li class="pp-dot" v-for="page in pagination"
              :class="{'pp-dot-active': meta.current_page === page }" :key="page">
            <a href="javascript:void(0)" @click="applyQuery({ page: page })">{{page}}</a>
          </li>
          <li class="pagination-point" v-if="meta.current_page !== meta.last_page">
            <a href="javascript:void(0)" @click="applyQuery({ page: meta.current_page+1 })"><i
                    class="fas fa-angle-right pag-right"></i> </a>
          </li>
          <li class="pagination-point" v-if="meta.current_page !== meta.last_page">
            <a href="javascript:void(0)" @click="applyQuery({ page: meta.last_page })"> <i
                    class="fas fa-angle-double-right pag-right"></i></a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import HistoryItem from './../../components/PageComponents/HistoryItem'
import HistoryService from '@/api-services/history.service'
export default {
  name: 'HistoryList',
  data () {
    return {
      items: [],
      meta: {},
      filters: {
        per_page: 25
      }
    }
  },
  watch: {
    '$route.query' () {
      this.getHistoryList()
    }

  },
  components: {
    HistoryItem
  },
  computed: {
    // return first page from pagination html
    start_page () {
      if (this.meta.last_page > 5 && this.meta.current_page > 2) {
        return parseInt(this.meta.current_page, 10) - 2
      } else {
        return 1
      }
    },
    // return last page from pagination html
    stop_page () {
      if (this.meta.last_page > 5 && this.meta.current_page + 2 < this.meta.last_page) {
        return parseInt(this.meta.current_page, 10) + 2
      } else {
        return this.meta.last_page
      }
    },
    // return only 5 pages for pagination
    pagination () {
      let pages = []
      for (let i = this.start_page; i <= this.stop_page; i++) {
        pages.push(i)
      }
      return pages
    }
  },
  methods: {
    getHistoryList (params = null) {
      HistoryService.getAllHistory(params)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.items = response.data.data
            this.meta = response.data.meta
          } else {
            this.items = []
            this.meta = {}
          }
          this.$store.dispatch('hideLoading')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    applyQuery (params) {
      this.$router.push({ path: '/history', query: params })
    }
  },
  async created () {
    await this.getHistoryList()
    this.$store.dispatch('hideLoading')
  }
}
</script>

<style>

</style>
