import axios from 'axios'

const RESOURCE_NAME = 'history'

export default {
  getAllHistory (params) {
    let apiParams = new URLSearchParams(window.location.search).toString()
    if (params) {
      apiParams = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
    }
    if (apiParams) {
      return axios.get(`${RESOURCE_NAME}?${apiParams.toString()}`)
    } else {
      return axios.get(`${RESOURCE_NAME}`)
    }
  }
}
